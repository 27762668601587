import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";

import {
  appSomethingIsLoading,
  appAllLoaded,
  appSetSearchOfficesOnly,
} from '../actions';

import mongoConfig from "../mongo";

/**
 * Custom hook to fetch and filter a list of offices from a MongoDB collection based on selected office status and group.
 *
 * @param {string} selectedOfficeStatus - The status of the office to filter by (e.g., 'active', 'inactive', 'all').
 * @param {string} selectedGroup - The ID of the selected group to filter offices by.
 * @param {string} selectedGroupName - The name of the selected group to filter offices by.
 * @returns {Array|null} - A list of filtered and formatted offices, or null if no offices are found.
 */
const useDropdownOffices = (selectedOfficeStatus, selectedGroup) => {
  const dispatch = useDispatch();
  const mongoUser = useSelector((state) => state.auth.user);
  const [officesList, setOfficesList] = useState(null);

  useEffect(() => {
    dispatch(appSomethingIsLoading());

    let officesFilter = {};
    if (selectedGroup) {
      const groupID = selectedGroup._id.toString();
      const groupName = selectedGroup.name;
      console.log('groupID:', groupID);
      console.log('groupName:', groupName);
      officesFilter = {
        $or: [
          { groupID: groupID },
          { groupName: groupName }
        ]
      };
    }
    if (selectedOfficeStatus && selectedOfficeStatus !== 'all') {
      officesFilter['officeStatus'] = selectedOfficeStatus;
    }

    mongoUser
      .mongoClient(mongoConfig.realmServiceName)
      .db(mongoConfig.databaseName)
      .collection('offices')
      .find(officesFilter, {
        sort: { 'name': 1 },
        projection: { _id: 1, name: 1, address: 1, groupID: 1, groupName: 1 } // Include address in the projection
      })
      .then(offices => {
        dispatch(appAllLoaded());
        let renamedOffices = offices.map((e) => {
          const address = `${e.address.street}, ${e.address.city}, ${e.address.state} ${e.address.zip}`;
          return { id: e._id, label: `${e.name}`, address: `${address}` }; // Append full address to the label
        });
        let officesIDs = offices.map((e) => (e._id));

        if (selectedOfficeStatus && selectedOfficeStatus !== 'all') {
          dispatch(appSetSearchOfficesOnly(officesIDs));
        } else {
          dispatch(appSetSearchOfficesOnly(null));
        }
        setOfficesList(renamedOffices);
      });
  }, [dispatch, mongoUser, selectedGroup, selectedOfficeStatus]);

  return officesList;
}

export default useDropdownOffices;
