import { appConstants as ac } from '../constants';

export const dialogReset = () => {
  return {
    type: ac.APP_DIALOG_RESET
  };
};

export const dialogInProgress = () => {
  return {
    type: ac.APP_DIALOG_IN_PROGRESS
  };
};

export const dialogSuccess = () => {
  return {
    type: ac.APP_DIALOG_SUCCESS
  };
};

export const dialogError = (error) => {
  return {
    type: ac.APP_DIALOG_ERROR,
    error
  };
};

export const appSomethingIsLoading = () => {
  return {
    type: ac.APP_SOMETHING_IS_LOADING
  };
};

export const appAllLoaded = () => {
  return {
    type: ac.APP_ALL_LOADED
  };
};

export const appGraphIsLoading = (loadingKey) => {
  return {
    type: ac.APP_GRAPH_IS_LOADING,
    loadingKey
  };
};

export const appGraphLoaded = (loadingKey) => {
  return {
    type: ac.APP_GRAPH_LOADED,
    loadingKey
  };
};

export const appHotHoursIsLoading = () => {
  return {
    type: ac.APP_HOTHOURS_IS_LOADING
  };
};

export const appHotHoursLoaded = () => {
  return {
    type: ac.APP_HOTHOURS_LOADED
  };
};

export const appTableIsLoading = () => {
  return {
    type: ac.APP_TABLE_IS_LOADING
  };
};

export const appTableLoaded = () => {
  return {
    type: ac.APP_TABLE_LOADED
  };
};

export const appFilterReset = () => {
  return {
    type: ac.APP_FILTER_RESET
  };
};

export const appSetSearchOfficesOnly = (searchOfficesOnly) => {
  return {
    type: ac.APP_SET_SEARCH_OFFICES_ONLY,
    searchOfficesOnly
  };
};

export const appSetFilterOfficeStatus = (selectedOfficeStatus) => {
  return {
    type: ac.APP_SET_FILTER_OFFICE_STATUS,
    selectedOfficeStatus
  };
};

export const appSetFilterGroup = (selectedGroup) => {
  return {
    type: ac.APP_SET_FILTER_GROUP,
    selectedGroup,
  };
};

export const appSetFilterOffice = (selectedOffice) => {
  return {
    type: ac.APP_SET_FILTER_OFFICE,
    selectedOffice
  };
};

export const appSetFilterNumber = (selectedNumber) => {
  return {
    type: ac.APP_SET_FILTER_NUMBER,
    selectedNumber
  };
};
export const add = (a, b) => {
  return a + b;
}
export const subtract = (a, b) => {
  return a - b;
}